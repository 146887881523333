import {useEffect} from 'react';
import {useNavigate, useLocation, Location} from 'react-router-dom';
import {LoginForm, GenericObject} from 'components';
import {useFirebaseContext} from 'hooks';
import {isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';
import styled from '@emotion/styled';
import {ROUTES} from 'routes';

export const SignIn = () => {
  const {
    firebaseUser, 
    auth, 
    setFirebaseUser
  } = useFirebaseContext();

  const navigate = useNavigate();

  const location: Location = useLocation();

  useEffect(() => {
    (async () => {
      if (!auth) return;
      window.scrollTo(0, 0);
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const params = new URLSearchParams(window.location.search);
        let email = params.get('email');
        if (!email) email = window.localStorage.getItem('signInEmail');
        if (!email) {
          navigate(ROUTES.home);
          return;
        }
        try {
          const result = await signInWithEmailLink(
            auth!,
            email,
            window.location.href
          );
          window.localStorage.removeItem('emailForSignIn');
          if (result?.user) setFirebaseUser!(result.user);
          navigate(params.get('to') || ROUTES.home);
        } catch (err: any) {
          navigate(ROUTES.home);
        }
      }
    })();
  }, [location.search, navigate]);

  const returnToPage = (data?: GenericObject) => {
    let url = data?.home || ROUTES.home;
    try {
      const state: {[key:string]:any} = location.state || {};
      if (location && location.state) {
        const {
          from: {
            pathname
          },
        } = state;
        if (pathname?.substring(0, 1) === '/') {
          url = pathname;
        }
      }
    } catch (err: any) {
      console.error(err.message);
    }
    navigate(url, {replace: true, state: location.state || {}});
  };

  if (firebaseUser) {
    returnToPage();
  }

  return (
    <>
      <FormContainer>
        <LoginForm onSuccess={returnToPage} state={location?.state} />
      </FormContainer>
    </>
  );
};

const FormContainer = styled.div`
  width: 98%;
  margin: 0 auto 40px auto;
  @media only screen and (min-device-width: 768px) {
    max-width: 400px;
  }
`;
