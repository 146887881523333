import {createContext, useReducer, useEffect} from 'react';
import {initializeApp, getApp, getApps, FirebaseApp} from 'firebase/app';
import {getAuth, onAuthStateChanged, User, Auth} from 'firebase/auth';
import * as firestore from 'firebase/firestore';
import 'firebase/database';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {reducer} from 'lib';
import {
  getStorage,
  FirebaseStorage,
} from 'firebase/storage';

export interface GenericObject {[key:string]:any};

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

//firestore.setLogLevel('debug');

const app = initializeApp(config);

const firestoreSettings: firestore.FirestoreSettings & {
  useFetchStreams: boolean;
} = {
  useFetchStreams: false,
};

const db = firestore.initializeFirestore(app, firestoreSettings);

const auth = getAuth(app);

const functions = getFunctions(app);

const initialState = {
  firebaseUser: null,
};

export const defaultListenerOptions = {
  snapshotListenOptions: { 
    includeMetadataChanges: true 
  },
};

export interface FirebaseContextProps {
  firebaseUser: User;
  defaultListenerOptions: GenericObject;
  callable: (name: string, data?: GenericObject) => Promise<any>;
  getBatch: () => firestore.WriteBatch;
  setFirebaseUser: (user: any) => void;
  db: firestore.Firestore;
  app: FirebaseApp;
  auth: Auth;
  getCdn: () => FirebaseStorage;
}

export const FirebaseContext = createContext<Partial<FirebaseContextProps>>({});

export const FirebaseProvider = ({children}:{ children: any }) => {
  const [
    {
      firebaseUser,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => { 
    (async () => {
      onAuthStateChanged(auth, async user => {
        dispatch({firebaseUser: user});
      });
    })();
  }, []);

  const getCdn = () => {
    let a = getApp();
    return getStorage(a, `gs://${(process.env.REACT_APP_CDN || '').replace('https://', '').replace('storage.googleapis.com/', '')}`);
  }

  const getBatch = () => firestore.writeBatch(db);

  const callable = async (name: string, data?: GenericObject): Promise<any> => {
    const func = httpsCallable(functions, name);
    try {
      return await func(data || {});
    } catch (error) {
      console.log(error);
      return {error};
    }
  };

  const setFirebaseUser = (user: any) => dispatch({firebaseUser: user});

  return (
    <FirebaseContext.Provider
      value={{
        db,
        app,
        auth,
        callable,
        getBatch,
        firebaseUser,
        setFirebaseUser,
        getCdn,
        defaultListenerOptions,
      }}
    >
      {getApps().length > 0 ? (
        children
      ) : (
        <></>
      )}
    </FirebaseContext.Provider>
  );
};
