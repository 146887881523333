import {EmailLogin} from 'components';
import type {UserCredential, UserInfo} from 'firebase/auth';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { ROUTES } from 'routes';

interface Props {
  onSuccess: (result: UserCredential | UserInfo) => void;
  state?: {[key:string]:any} | null; 
  providers?: string[];
}

export const LoginForm = ({onSuccess = () => {}, state = null, providers }: Props) => (
  <Box>
    {state?.message && (
      <Box mb={2}>
        <Alert severity="info">{state.message}</Alert>
      </Box>
    )}
    <EmailLogin onSuccess={onSuccess}/>
  </Box>
);
