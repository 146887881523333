import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthenticatedRoute } from 'components';
import { Home } from './Home';
import { SignIn } from './SignIn';

export const ROUTES = {
    home: '/',
    signin: '/sign-in',
};

export const InternalRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path={ROUTES.home} element={<AuthenticatedRoute/>}>
                    <Route index path={ROUTES.home} element={<Home/>}/>
                </Route>
                <Route path={ROUTES.signin} element={<SignIn/>}/>
            </Routes>
        </Router>
    )
}