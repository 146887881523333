"use client";
//import { FirebaseProvider, AppProvider, GameProvider } from 'providers';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { FirebaseProvider } from 'providers';
import { InternalRoutes } from 'routes';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme => theme}>
      <CssBaseline/>
        <FirebaseProvider>
          <InternalRoutes/>
        </FirebaseProvider>
    </ThemeProvider>
  </MuiThemeProvider>
)

export default App;
