import { useState } from 'react';
import { collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useFirebaseContext } from 'hooks';
import { DateTime } from 'luxon';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';

export const Home = () => {

    const { db, defaultListenerOptions } = useFirebaseContext();

    const [periodOpen, setPeriodOpen] = useState<boolean>(false);

    const [data, loading, error] = useCollection(
        collection(db!, 'dates'),
        defaultListenerOptions,
    )

    if (error) {
        return <p>Error getting data: {error.message}</p>
    }

    if (!data?.docs?.length) return <p>Please wait...</p>;

    const today = DateTime.now().day;

    const upper_boundary = today > 15 ? DateTime.now().daysInMonth || 30 : 15;

    const month = data?.docs?.filter(doc => doc.get('day') >= today)
        .reduce((total, next) => total += next.get('amount'), 0);

    const period = data?.docs?.filter(doc => doc.get('day') >= today && doc.get('day') <= upper_boundary)
        .reduce((total, next) => total += next.get('amount'), 0);

    const days_left = today > 15 
        ? upper_boundary - today - 15
        : upper_boundary - today;

    return (
        <Container>
            <Box textAlign='center' mt={3} mb={3}>
                <Typography component='h1' variant='h4'>
                    Today is {DateTime.now().toFormat('LLL dd')}
                </Typography>
                <Typography>
                    ({days_left} days left in pay-period)
                </Typography>
            </Box>
            <Box textAlign='center'>
                <Typography component='h1' variant='h4'>
                    Remaining Drafts:
                </Typography>
                <Typography fontSize='1.5rem'>
                    Pay-Period: ${period}
                </Typography>
                <Typography fontSize='1.5rem'>
                    Month: ${month}
                </Typography>
                <IconButton onClick={() => setPeriodOpen(!periodOpen)}>
                {periodOpen 
                    ? <ExpandLess sx={{cursor:'pointer'}}/> 
                    : <ExpandMore sx={{cursor:'pointer'}}/>
                }
                </IconButton>
                <Collapse in={periodOpen} timeout="auto" unmountOnExit>
                    <Box>
                        <Typography fontWeight='bold'>
                            Pay-Period (${period})
                        </Typography>
                        <Box maxWidth={'500px'} margin={'0 auto'} mb={3}>
                            {data?.docs?.filter(doc => doc.get('day') >= today && doc.get('day') <= upper_boundary)
                                .sort((a, b) => a.get('day') - b.get('day'))
                                .map(doc => {
                                return (
                                    <Grid container>
                                        <Grid item textAlign='right' xs={1}>
                                            {doc.get('day')}
                                        </Grid>
                                        <Grid item xs={9} textAlign='left' paddingLeft={2}>
                                            {doc.get('description')}
                                        </Grid>
                                        <Grid item textAlign='right' xs={2}>
                                            ${doc.get('amount')}
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Box>
                        <Typography fontWeight='bold'>
                            Month (${month})
                        </Typography>
                        <Box maxWidth={'500px'} margin={'0 auto'}>
                            {data?.docs?.filter(doc => doc.get('day') >= today)
                                .sort((a, b) => a.get('day') - b.get('day'))
                                .map(doc => {
                                return (
                                    <Grid container>
                                        <Grid item textAlign='right' xs={1}>
                                            {doc.get('day')}
                                        </Grid>
                                        <Grid item xs={9} textAlign='left' paddingLeft={2}>
                                            {doc.get('description')}
                                        </Grid>
                                        <Grid item textAlign='right' xs={2}>
                                            ${doc.get('amount')}
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Container>
    )
    
}
